export default [
  {
    path: '/admin/defines/parkour-types',
    name: 'ParkourTypes',
    component: () => import('@/views/Admin/Defines/Parkour_types/Index.vue'),
    meta: {
      pageTitle: 'Parkur Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/parkour-types/add',
    name: 'ParkourTypeAdd',
    component: () => import('@/views/Admin/Defines/Parkour_types/Add.vue'),
    meta: {
      pageTitle: 'Parkur Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/parkour-types',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/parkour-types/edit/:id',
    name: 'ParkourTypeEdit',
    component: () => import('@/views/Admin/Defines/Parkour_types/Edit.vue'),
    meta: {
      pageTitle: 'Parkur Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/parkour-types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
