import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/offer_mailsend'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      mailto: [],
      mailcc: [],
      mailbcc: [],
      mailsubject: null,
      mailbody: null,
      id_offers: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    sending: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    sending(state) {
      return state.sending
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_SENDING(state, data) {
      state.sending = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        mailto: [],
        mailcc: [],
        mailbcc: [],
        mailsubject: null,
        mailbody: null,
        id_offers: null,
      }
      state.dataSave = {
        message: null,
        status: null,
        id: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, params) {
      const data = JSON.stringify({
        where: {
          'offer_mailsend.id': params.id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    sendMail({ commit }, postData) {
      commit('SET_SENDING', true)
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/offerMail`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_SENDING', false)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
