import Vue from 'vue'
import Vuex from 'vuex'

/* Admin:Core */
import smtpConfig from '@/store/Admin/Core/smtpconfig'
import users from '@/store/Admin/Core/users'
import userTypes from '@/store/Admin/Core/user_types'
import dealerUserTypes from '@/store/Admin/Core/dealer_user_types'
import customerUsersTypes from '@/store/Admin/Core/customer_users_types'
import abilities from '@/store/Admin/Core/abilities'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app/index'
import profile from './app/profile'
/* Admin:Defines */
import countries from './Admin/Defines/countries'
import cities from './Admin/Defines/cities'
import currencies from './Admin/Defines/currencies'
import customerTypes from './Admin/Defines/customer_types'
import interviewStatuses from './Admin/Defines/interview_statuses'
import interviewSubjects from './Admin/Defines/interview_subjects'
import meets from './Admin/Defines/meets'
import offerStatuses from './Admin/Defines/offer_statuses'
import offerTerms from './Admin/Defines/offer_terms'
import sectors from './Admin/Defines/sectors'
import serviceStatuses from './Admin/Defines/service_statuses'
import serviceTypes from './Admin/Defines/service_types'
import taxes from './Admin/Defines/taxes'
import units from './Admin/Defines/units'
import activities from './Admin/Defines/activities'
import relatedPersonTypes from './Admin/Defines/related_person_types'
import machineTypes from './Admin/Defines/machine_types'
import parkourTypes from './Admin/Defines/parkour_types'
import banks from './Admin/Defines/banks'
import brands from './Admin/Defines/brands'
/* Admin:App */
import customers from './Admin/App/customers'
import customerAddresses from './Admin/App/customer_addresses'
import customerRelatedPersons from './Admin/App/customer_related_persons'
import customerSectors from './Admin/App/customer_sectors'
import customerActivities from './Admin/App/customer_activities'
import customerMachineParkour from './Admin/App/customer_machine_parkour'
import customerMachines from './Admin/App/customer_machines'
import customerHistory from './Admin/App/customer_history'
import customerUsers from './Admin/App/customer_users'
import dashboard from './Admin/App/dashboard'
import dealers from './Admin/App/dealers'
import dealerAddresses from './Admin/App/dealer_addresses'
import dealerUsers from './Admin/App/dealer_users'
import productGroups from './Admin/App/product_groups'
import products from './Admin/App/products'
import interviews from './Admin/App/interviews'
import interviewFiles from './Admin/App/interview_files'
import reminders from './Admin/App/reminders'
import services from './Admin/App/services'
import serviceFiles from './Admin/App/service_files'
import serviceMailsend from './Admin/App/service_mailsend'
import offers from './Admin/App/offers'
import offerMailsend from './Admin/App/offer_mailsend'
import bankAccounts from './Admin/App/bank_accounts'
import proformaInvoices from './Admin/App/proforma_invoices'
/* Admin:Reports */
import interviewReports from './Admin/Reports/interviewReports'
import serviceReports from './Admin/Reports/serviceReports'
import serviceAccountingReports from './Admin/Reports/serviceAccountingReports'
import offerReports from './Admin/Reports/offerReports'
import proformaReports from './Admin/Reports/proformaReports'
import customerReports from './Admin/Reports/customerReports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    profile,
    smtpConfig,
    users,
    userTypes,
    dealerUserTypes,
    customerUsersTypes,
    abilities,
    countries,
    cities,
    currencies,
    customerTypes,
    customerUsers,
    interviewStatuses,
    interviewSubjects,
    meets,
    offerStatuses,
    offerTerms,
    sectors,
    serviceStatuses,
    serviceTypes,
    taxes,
    units,
    activities,
    relatedPersonTypes,
    machineTypes,
    parkourTypes,
    banks,
    brands,
    customers,
    customerAddresses,
    customerRelatedPersons,
    customerSectors,
    customerActivities,
    customerMachineParkour,
    customerMachines,
    customerHistory,
    dashboard,
    dealers,
    dealerAddresses,
    dealerUsers,
    productGroups,
    products,
    interviews,
    interviewFiles,
    reminders,
    services,
    serviceFiles,
    serviceMailsend,
    offers,
    offerMailsend,
    bankAccounts,
    proformaInvoices,
    interviewReports,
    serviceReports,
    serviceAccountingReports,
    offerReports,
    proformaReports,
    customerReports,
  },
  strict: process.env.DEV,
})
