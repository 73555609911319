import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/reports/services'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: 0,
    filter: {
      sdate: null,
      edate: null,
      id_users: null,
      limit: 25,
      start: 0,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    filterParams(state) {
      return state.filter
    },
    dataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.dataList = data.data
      state.dataCount = data.count
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getReport({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/ServiceAccountingReports`, data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    changeStatus({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/ServiceAccountingAction`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
  },
}
