import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/products'
const defaultItems = {
  id: null,
  title: null,
  content: null,
  technical: null,
  id_product_groups_main: null,
  id_product_groups: null,
  images: [],
  files: [],
  upload_images: [],
  upload_files: [],
}
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      title: null,
      content: null,
      technical: null,
      id_product_groups_main: null,
      id_product_groups: null,
      id_brands: null,
      images: [],
      files: [],
      upload_images: [],
      upload_files: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          id: null,
          title: null,
          content: null,
          technical: null,
          id_product_groups_main: null,
          id_product_groups: null,
          id_brands: null,
          images: [],
          files: [],
          upload_images: [],
          upload_files: [],
        }
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'products.id': id,
        },
        array: false,
        detail: true,
        isAdmin: true,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const formData = new FormData()
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in postData) {
        if (!key.includes('upload_images', 'upload_files')) {
          formData.append(key, postData[key])
        }
      }
      if (postData.upload_images) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const galleryKey in postData.upload_images) {
          formData.append('upload_images[]', postData.upload_images[galleryKey])
        }
      }
      axiosIns
        .post(`${ROOT_URL}/saveData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeImageData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteImage`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    resetDataItem({ commit }) {
      commit('RESET_DATA_ITEM', true)
    },
    reOrderingImages({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/reOrderingImages`, data)
        .then(response => {
          // commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
