export default [
  {
    path: '/app/reports/interviews',
    name: 'ReportInterviews',
    component: () => import('@/views/Admin/Reports/Interviews/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Görüşmeler',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_INTERVIEW',
      action: 'read',
    },
  },
  {
    path: '/app/reports/offers',
    name: 'ReportOffers',
    component: () => import('@/views/Admin/Reports/Offers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_OFFER',
      action: 'read',
    },
  },
  {
    path: '/app/reports/services',
    name: 'ReportServices',
    component: () => import('@/views/Admin/Reports/Services/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Servis',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_SERVICE',
      action: 'read',
    },
  },
  {
    path: '/app/reports/proforma',
    name: 'ReportProforma',
    component: () => import('@/views/Admin/Reports/Proforma/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Proforma',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_PROFORMA',
      action: 'read',
    },
  },
  {
    path: '/app/reports/service-accounting',
    name: 'ReportServiceAccounting',
    component: () => import('@/views/Admin/Reports/ServiceAccounting/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Servis Muhasebe',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_SERVICE_ACCOUNTING',
      action: 'read',
    },
  },
  {
    path: '/app/reports/customers',
    name: 'ReportCustomers',
    component: () => import('@/views/Admin/Reports/Customers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Müşteri Kartı Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_CUSTOMERS',
      action: 'read',
    },
  },
  {
    path: '/app/reports/customers/detail/:id',
    name: 'ReportCustomerDetail',
    component: () => import('@/views/Admin/Reports/Customers/Detail.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Müşteri Kartı Arama',
          to: '/app/reports/customers',
          active: false,
        },
        {
          text: 'Müşteri Detayı',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_CUSTOMERS',
      action: 'read',
    },
  },
]
