export default [
  {
    path: '/admin/customer-users-types',
    name: 'CustomerUsersTypes',
    component: () => import('@/views/Admin/Customer_users_types/Index.vue'),
    meta: {
      pageTitle: 'Müşteri Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_USERS_TYPES',
      action: 'read',
    },
  },
  {
    path: '/admin/customer-users-types/add',
    name: 'CustomerUsersTypeAdd',
    component: () => import('@/views/Admin/Customer_users_types/Add.vue'),
    meta: {
      pageTitle: 'Müşteri Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/customer-users-types',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_USERS_TYPES',
      action: 'read',
    },
  },
  {
    path: '/admin/customer-users-types/edit/:id',
    name: 'CustomerUsersTypeEdit',
    component: () => import('@/views/Admin/Customer_users_types/Edit.vue'),
    meta: {
      pageTitle: 'Müşteri Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/customer-users-types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_USERS_TYPES',
      action: 'read',
    },
  },
]
