export default [
  {
    path: '/app/services',
    name: 'Services',
    component: () => import('@/views/Admin/Services/Index.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/services/customer-search',
    name: 'ServiceCustomerSearch',
    component: () => import('@/views/Admin/Services/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/services',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/services/add/:id_customers',
    name: 'ServiceAdd',
    component: () => import('@/views/Admin/Services/Add.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/services',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/app/services/customer-search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/services/edit/:id',
    name: 'ServiceEdit',
    component: () => import('@/views/Admin/Services/Edit.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/services',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/services/view/:id',
    name: 'ServiceView',
    component: () => import('@/views/Admin/Services/View.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/services',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_SERVICE_VIEW',
      action: 'read',
    },
  },
]
