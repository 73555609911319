export default [
  {
    path: '/app/dealers',
    name: 'Dealers',
    component: () => import('@/views/Admin/Dealers/Index.vue'),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALER_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/dealers/add',
    name: 'DealerAdd',
    component: () => import('@/views/Admin/Dealers/Add.vue'),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/dealers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/dealers/edit/:id',
    name: 'DealerEdit',
    component: () => import('@/views/Admin/Dealers/Edit.vue'),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/dealers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/dealers/view/:id',
    name: 'DealerView',
    component: () => import('@/views/Admin/Dealers/View.vue'),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/dealers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALER_VIEW',
      action: 'read',
    },
  },
]
