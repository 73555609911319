import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/customer_history'
export default {
  namespaced: true,
  state: {
    dataList: {
      interviews: [],
      offers: [],
      services: [],
    },
    counters: {
      interviews: 0,
      offers: 0,
      services: 0,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    counters(state) {
      return state.counters
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTERS(state, data) {
      state.counters = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTERS', response.data.counters)
        })
        .catch(error => console.error(error))
    },
  },
}
