import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/interviews'
const defaultItems = {
  id: null,
  id_customers: null,
  id_interview_subjects: null,
  id_interview_statuses: '1',
  id_meets: null,
  content: null,
  reminder: {
    remind: null,
    content: null,
  },
}
export default {
  namespaced: true,
  state: {
    customerList: [],
    dataList: [],
    openData: {
      data: [],
      count: 0,
    },
    dataCounts: 0,
    dataItem: defaultItems,
    dataLine: {},
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataInterview: {},
    filterData: {
      id_brands: null,
      id_interview_subjects: null,
      id_meets: null,
      id_users: null,
      status: null,
      sdate: null,
      edate: null,
    },
    search: {
      keyword: null,
    },
    detailSearch: false,
  },
  getters: {
    customerList(state) {
      return state.customerList
    },
    openData(state) {
      return state.openData
    },
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataLine(state) {
      return state.dataLine
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataInterview(state) {
      return state.dataInterview
    },
    filterData(state) {
      return state.filterData
    },
    search(state) {
      return state.search
    },
    detailSearch(state) {
      return state.detailSearch
    },
  },
  mutations: {
    SET_CUSTOMER_LIST(state, data) {
      state.customerList = data
    },
    SET_OPEN_DATA(state, data) {
      state.openData = {
        data: data.data,
        count: data.count,
      }
    },
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_INTERVIEW(state, data) {
      state.dataInterview = data
    },
    SET_DATA_LINE(state, data) {
      state.dataLine = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        id_customers: null,
        id_interview_subjects: null,
        id_interview_statuses: '1',
        id_meets: null,
        content: null,
        reminder: {
          remind: null,
          content: null,
        },
      }
    },
    SET_DETAIL_SEARCH(state, data) {
      state.detailSearch = data
    },
  },
  actions: {
    customerSearch({ commit }, keyword) {
      axiosIns
        .post(`${ROOT_URL}/customerSearch`, { keyword })
        .then(response => {
          commit('SET_CUSTOMER_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    openControl({ commit }, params) {
      axiosIns
        .post(`${ROOT_URL}/openControl`, params)
        .then(response => {
          commit('SET_OPEN_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataInterview({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/getInterview`, { id_interviews: id })
        .then(response => {
          commit('SET_DATA_INTERVIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataLine({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/getLine`, { id })
        .then(response => {
          commit('SET_DATA_LINE', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    createInterview({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/createInterview`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    insertLine({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/insertLine`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('RESET_DATA_ITEM')
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    updateLine({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/updateLine`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeLine({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteLine`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteInterview`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
