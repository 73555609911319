export default [
  {
    path: '/admin/defines/machine-types',
    name: 'MachineTypes',
    component: () => import('@/views/Admin/Defines/Machine_types/Index.vue'),
    meta: {
      pageTitle: 'Makina Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/machine-types/add',
    name: 'MachineTypeAdd',
    component: () => import('@/views/Admin/Defines/Machine_types/Add.vue'),
    meta: {
      pageTitle: 'Makina Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/machine-types',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/machine-types/edit/:id',
    name: 'MachineTypeEdit',
    component: () => import('@/views/Admin/Defines/Machine_types/Edit.vue'),
    meta: {
      pageTitle: 'Makina Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/machine-types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
