import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/service_files'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      title: null,
      upload_file: null,
      id_services: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    uploading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        title: null,
        upload_file: null,
        id_services: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, id) {
      axiosIns
        .post(ROOT_URL, { id })
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    uploadData({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('title', postData.title)
      formData.append('upload_file', postData.upload_file)
      formData.append('id_services', postData.id_services)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_UPLOADING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
