import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/reports/customers'
export default {
  namespaced: true,
  state: {
    interviews: [],
    offers: [],
    proforma: [],
    services: [],
  },
  getters: {
    interviews(state) {
      return state.interviews
    },
    offers(state) {
      return state.offers
    },
    proforma(state) {
      return state.proforma
    },
    services(state) {
      return state.services
    },
  },
  mutations: {
    SET_INTERVIEWS_DATA(state, data) {
      state.interviews = data
    },
    SET_OFFERS_DATA(state, data) {
      state.offers = data
    },
    SET_PROFORMA_DATA(state, data) {
      state.proforma = data
    },
    SET_SERVICES_DATA(state, data) {
      state.services = data
    },
  },
  actions: {
    getReport({ commit }, data) {
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getInterviews({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/interviews`, { id_customers: id })
        .then(response => {
          commit('SET_INTERVIEWS_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getOffers({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/offers`, { id_customers: id })
        .then(response => {
          commit('SET_OFFERS_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getProforma({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/proforma`, { id_customers: id })
        .then(response => {
          commit('SET_PROFORMA_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getServices({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/services`, { id_customers: id })
        .then(response => {
          commit('SET_SERVICES_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
