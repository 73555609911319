export default [
  {
    path: '/admin/defines/brands',
    name: 'Brands',
    component: () => import('@/views/Admin/Defines/Brands/Index.vue'),
    meta: {
      pageTitle: 'Markalar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/brands/add',
    name: 'BrandAdd',
    component: () => import('@/views/Admin/Defines/Brands/Add.vue'),
    meta: {
      pageTitle: 'Markalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/brands',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/brands/edit/:id',
    name: 'BrandEdit',
    component: () => import('@/views/Admin/Defines/Brands/Edit.vue'),
    meta: {
      pageTitle: 'Markalar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/brands',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
