export default [
  {
    path: '/app/offers',
    name: 'Offers',
    component: () => import('@/views/Admin/Offers/Index.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/offers/open',
    name: 'OpenOffers',
    component: () => import('@/views/Admin/Offers/OpenIndex.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Açık Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/offers/customer-search',
    name: 'OfferCustomerSearch',
    component: () => import('@/views/Admin/Offers/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/offers/add/:id_customers',
    name: 'OfferAdd',
    component: () => import('@/views/Admin/Offers/Add.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/offers/edit/:id',
    name: 'OfferEdit',
    component: () => import('@/views/Admin/Offers/Edit.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/offers/revised/:id_offers',
    name: 'OfferEdit',
    component: () => import('@/views/Admin/Offers/Revised.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Revize Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/offers/view/:id',
    name: 'OfferView',
    component: () => import('@/views/Admin/Offers/View.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/app/offers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_OFFER_VIEW',
      action: 'read',
    },
  },
]
